export const routesPath = {
    MAIN_PATH: '/',
    BIRDSEYE_PATH: '/birdseye',
    RECORDINGS_PATH: '/recordings',
    SETTINGS_PATH: '/settings',
    HOSTS_PATH: '/hosts',
    HOST_CONFIG_PATH: '/hosts/:id/config',
    HOST_SYSTEM_PATH: '/hosts/:id/system',
    HOST_STORAGE_PATH: '/hosts/:id/storage',
    ACCESS_PATH: '/access',
    LIVE_PATH: '/cameras/:id/',
    EDIT_PATH: '/cameras/:id/edit',
    PLAYER_PATH: '/player',
    THANKS_PATH: '/thanks',
    USER_DETAILED_PATH: '/user',
    RETRY_ERROR_PATH: '/retry_error',
    TEST_PATH: '/test',
    FORBIDDEN_ERROR_PATH: '/403',
    NOT_FOUND_ERROR_PATH: '/404',
}