export const ExclamationCogWheel = (
    <svg
      width={284}
      height={269}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M246.427 113.72l-15.039-34.325L251.6 56.8l-27.4-25.9-23.774 19.204-37.084-14.416L154.814 5H128.04l-8.657 31.095L83.15 50.532 59.8 30.9 32.4 56.8l19.911 23.166-14.808 34.414L5 121.55v25.9l32.895 8.489 15.27 34.242L32.4 212.2l27.4 25.9 24.539-18.903 35.7 13.882L128.3 264h27.4l8.282-30.909 36.313-14.215c6.053 4.089 23.905 19.224 23.905 19.224l27.4-25.9-20.332-22.67 15.042-34.336 32.689-8.039.001-25.605-32.573-7.83z"
        stroke="#C92A2A"
        strokeWidth={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M142.101 164.994c-2.551 0-4.564-.807-6.041-2.423-1.342-1.616-2.147-3.904-2.416-6.866l-6.04-77.145c-.403-4.981.671-8.953 3.222-11.915 2.55-3.097 6.308-4.645 11.275-4.645 4.832 0 8.456 1.548 10.872 4.645 2.55 2.962 3.624 6.934 3.222 11.915l-6.041 77.145c-.134 2.962-.939 5.25-2.416 6.866-1.342 1.616-3.221 2.423-5.637 2.423zm0 42.006c-4.564 0-8.255-1.414-11.074-4.241-2.685-2.827-4.027-6.462-4.027-10.905 0-4.308 1.342-7.809 4.027-10.502 2.819-2.827 6.51-4.241 11.074-4.241 4.698 0 8.322 1.414 10.872 4.241 2.685 2.693 4.027 6.194 4.027 10.502 0 4.443-1.342 8.078-4.027 10.905-2.55 2.827-6.174 4.241-10.872 4.241z"
        fill="#C92A2A"
      />
    </svg>
  )